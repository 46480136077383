.customContainer {
    height: 250px;
}

.options {
    padding-top: 70px;
}

.question {
    float: left;
    text-align: left;
    width: 90%;
}

.timer {
    float: right;
    width: 10%;
}

.count {
    margin-top: 30px;
    text-align: center;
}

.submitBtn {
    float: left;
    margin-top: 30px !important;
}

.stateContainer {
    margin-top: 75px;
}

.gif-container {
    width: 100%;
    height: 0;
    padding-bottom: 45%;
    position: relative;
}

.gif {
    width: 480;
    height: 217;
}

strong {
    font-weight: bold !important;
}

.answer {
    float: left;
    text-align: left;
    width: 1000px;
    height: 200px;
    padding-top: 40px;
}

.nextBtn {
    margin-top: 55px !important;
    margin-left: 150px !important;
}

.selection {
    float: left;
    color: #008fd4;
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 10px;
}

.feedback {
    color: #008fd4;
    font-weight: bold;
    font-style: italic;
}

.viaGiphy {
    color: black;
    font-size: x-small;
    margin: 0px !important;
    line-height: .0001rem !important;
}