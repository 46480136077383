@font-face {
  font-family: "Pacifica W00 Condensed";
  src: local("Pacifica W00 Condensed"),
    url(./fonts/PacificaCondensedRegular.ttf) format("woff");
}

body {
  margin: 0;
  font-family: Georgia, "Times New Roman", Times, serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.h1-upward {
  color: #008fd4;
  font-family: "Pacifica W00 Condensed";
  font-weight: bold;
  font-size: 42px;
  text-align: center;
  margin-top: 25px;
  margin-bottom: 20px;
}

.h3-article {
  font-weight: bold;
  color: #008fd4;
  text-align: right;
  margin-top: 25px;
  margin-bottom: 20px;
}

button:disabled {
  background: #dddddd;
}

.btn-upward {
  color: #ffffff;
  background-color: #008fd4;
}

.clickable-icon {
  cursor: pointer;
}

th {
  white-space: nowrap;
}
tbody + thead{
  display: none;
}

thead {
  color: #008fd4;
}

th {
  font-weight: bold !important;
  font-size: 16px !important;
}

.articles-table{
  cursor: pointer;
}

.article-div div{
  font-size: 14px !important;
}

.remove-space{
  line-height: 1rem !important;
  font-size: small !important;
}