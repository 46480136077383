.bm-burger-button {
    position: fixed;
    width: 30px;
    height: 25px;
    left: 10px;
    top: 15px;
}

.bm-burger-bars {
    background: rgba(255, 255, 255, .5);
}

.bm-burger-bars-hover {
    background: rgba(255, 255, 255, 1);
}

.bm-cross-button {
    height: 24px;
    width: 24px;
}

.bm-cross {
    background: #bdc3c7;
}

.bm-menu-wrap {
    position: fixed;
    height: 100%;
}

.bm-menu {
    background: #373a47;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
}

.bm-morph-shape {
    fill: #373a47;
}

.bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
}

.bm-item {
    display: inline-block;
}

.bm-overlay {
    background: rgba(0, 0, 0, 0.3);
}

#home {
    padding-left: 20px;
}