.custom-form {
    width: 50%;
    margin-top: 5%;
    margin-bottom: 10%;
    border-radius: 20px;
}

.react-phone-number-input__icon-image, .react-phone-number-input__icon--international {
    margin-bottom: 15px;
}

.password-description {
    margin-top: -20px !important;
    margin-bottom: 20px !important;
}

input[type="tel"]:disabled {
    background: white;
}

#hobbies-label {
    float: left;
    font-size: 1rem;
    color: #757575;
}

.noMarg {
    margin-bottom: 0px !important;
}