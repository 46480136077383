.customDisplay {
  width: 30% !important;
  margin-top: 5%;
  margin-bottom: 10%;
  border-radius: 20px;
}

#username {
  display: inline-block;
}

.extra {
  display: inline-block;
}

.user-location {
  margin-top: -25px !important;
  margin-bottom: 10px !important;
}

#hobbies {
  margin: auto;
  width: 100%;
  height: 100%;
  word-wrap: break-word;
  white-space: initial;
}

#score {
  margin-top: 20px;
}
